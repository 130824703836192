import React from "react";
import { connect } from "react-redux"
import { toggleHelpScreen } from '../../redux/actions'
import LightboxOverlay from './LightboxOverlay'


class HelpScreen extends React.Component {
  render(){

    return(
      <React.Fragment>
       {this.props.showHelpScreen &&
      <LightboxOverlay
        onClose={() => this.props.toggleHelpScreen({value:false})}
        header={"Help"}
      >

        <div className="helpContainerWrapper">
        <div className="helpContainer">
              <p>The main purpose of Accordion Helper is to find chords and generate chords:</p>
            <h2>Chord Finder</h2>
            <span className="firstSentenceHighlight">If you know the keys and buttons, but want to find a chord name: </span>Simply click/tap the keys or buttons on the accordion and, based on what is highlighted, it will try to name the chord and display it on the bottom of the screen.
              <p><span className="firstSentenceHighlight"> There are three separate sections that display chords relative to their locations:</span>
          <br/>  • Below the bass buttons on the left, is the chord created by just the left side selection. The blue arrows cycle through variations.
            <br/>• Below the keyboard on the right, is the chord created by just the keyboard selection.
            <br/>• In the middle is the chord name based on the combination of all notes on the accordion.</p>

            <h2>Chord Generator</h2> <span className="firstSentenceHighlight">If you have a chord name but don't know how to play it,</span> or you want to find a different way to play it:
              Use the yellow buttons to choose the root note of the chord and the chord name. After both are selected, the Chord Generator will highlight the notes that create the chord.
              In the bass section, the blue arrows next to the chord name cycle through different ways to play a chord.

            <h2>A combination of both chord finder and chord generator.</h2>
            Once you generate a chord, you can click around on the keys and buttons to see if you can modify it to something you need or want. It's definitely important to have your accordion nearby to test what the generator came up with -- your ear will have to be the final answer, but hopefully the Accordion Helper can help you out.
          </div>
        </div>

      </LightboxOverlay>

    }
      </ React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    showHelpScreen: state.showHelpScreen,
  }
}

const mapActionToProps = {
  toggleHelpScreen,

}
export default connect(mapStateToProps, mapActionToProps)(HelpScreen)
