import React from "react";
import PropTypes from 'prop-types'
import { getFormattedNoteName } from '../utils/musicHelpers'

const ButtonText = (props) => {
  if(props.notes.length > 1){
    return <div className="ButtonText">
            <div>{getFormattedNoteName(props.notes[0])}</div>
            <div>{getFormattedNoteName(props.notes[1])+ ' '+ getFormattedNoteName(props.notes[2])}</div>
          </div>
  }else if(props.notes && props.notes.length !== ''){
    return getFormattedNoteName(props.notes[0])
  }else{
    return null
  }
}

class BassButton extends React.Component {

  state = {
    // displayNote: true,
    selected: false,
  }

  containsDotted(note){
    return note.toLowerCase() === 'c' ? true :
           note.toLowerCase() === 'e' ? true :
           note.toLowerCase() === 'ab' ? true : false;

  }

  setCSS = () => {
    let classes = "BassButton "
    classes += (this.props.selected ? "BassButtonHighlight" : "")
    classes += (this.props.label === "root" && this.containsDotted(this.props.notes[0]) ? "BassButtonDotted " : "")
    classes += (this.props.smallKeyboardOption ? " Small" : "")

    return classes
  }

  onClick = () => {
    this.props.handleBassSelect({
      label: this.props.label,
      index: this.props.index,
    },
      this.props.selected
    )
  }

  render(){
    return (
        <div className={this.setCSS()} onClick={() => this.onClick()}>
          <ButtonText notes={(this.props.displayNote) && this.props.notes } />
        </div>
    )
  }
}

BassButton.propTypes = {
  notes: PropTypes.array,
  label: PropTypes.string,
  index: PropTypes.number,
  handleBassSelect: PropTypes.func,
}
export default BassButton
