import React from 'react';
import PropTypes from 'prop-types'
import ScrollArea from 'react-scrollbar'

import OptionButton from './OptionButton'


class OptionButtonRow extends React.Component {

  shouldComponentUpdate(nextProps){
    if(this.props.selected !== nextProps.selected || this.props.horizontal !== nextProps.horizontal){
      return true
    }else{
      return false
    }

  }

  isSelected = (item) => {
    if(this.props.selected === (typeof item == "string" ? item : item[this.props.keyOfItemValue]) ){
      return true
    } else if (this.props.selected === (typeof item == "string" ? item : item[this.props.keyOfItemTitle]) ) {
      return true
    }else{
      return false
    }
  }

  render(){
    const {list, keyOfItemTitle, keyOfItemValue, containerClass, itemClass, selected} = this.props
    return(
      <ScrollArea
        className={containerClass}
        speed={0.8}
        contentClassName={this.props.scrollContentClass || ""}
        vertical={!this.props.horizontal}
        horizontal={this.props.horizontal}
      >
      <div className='optionButtonRowHeader'>{this.props.headerText && this.props.headerText}</div>
      {
          list.map( (item, inx, arr) => {

            let styles = itemClass + ' '

            return <OptionButton
                      key={inx}
                      title={ (typeof item == "string") ? item : item[keyOfItemTitle] }
                      value={ (typeof item == "string") ? item : item[keyOfItemValue]}
                      index={inx}
                      styleClass={styles}
                      onClick={(value) => this.props.onItemClick(value)}
                      selected={this.isSelected(item)}
                    />

          })
      }
      </ScrollArea>

    )
  }
}

// selected={item === this.state.noteSelected}

OptionButtonRow.propTypes = {
  list: PropTypes.array,
  keyOfItemTitle: PropTypes.string,
  keyOfItemValue: PropTypes.string,
  styleClass: PropTypes.string,
  onItemClick: PropTypes.func,
}
export default OptionButtonRow
