
//https://stackoverflow.com/questions/4770025/how-to-disable-scrolling-temporarily

function scrollEnable(){
  document.body.classList = ""

}
function scrollDisable(){
  document.body.classList = "noPointerEvents"
  
}


export{
  scrollEnable,
  scrollDisable,
}
