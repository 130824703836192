import {store} from '../redux/store'
import {removeRepeatNotes} from './musicHelpers'
import {getEnharmonic} from '../chordFinder/chordFinder'
import {isPhysicallyImpossibleFingering, doesBassVoiceUseRootColumn, getBassVoiceFingeringStretch} from './bassboardChordSorting'

function getBassMatrix(){
  const arr = store.getState().bassNoteMatrix
  return arr
}

function getBassNotesFromMatrix(label, index){
  let bassNotes = []
  bassNotes = getEnharmonic(getBassMatrix()[label][index])
  bassNotes = (typeof bassNotes === "string" ? [bassNotes] : bassNotes)
  return bassNotes
}

function compareGeneratedChordToBassTriad(button, chordArr){//true if chordArr is within button
  for(let i = 0; i < chordArr.length; i++){
    const buttonArrEnharmonic = button.map( item => getEnharmonic(item))
    if( !buttonArrEnharmonic.includes(getEnharmonic(chordArr[i])) ) return false
  }
  return true
}

function isGeneratedChordWithinBassTriad(button, chordArr){// true if notes of button are within chordArr
  for(let i = 0; i < button.length; i++){
    const chordArrEnharmonic = chordArr.map( item => getEnharmonic(item))
    if( !chordArrEnharmonic.includes(getEnharmonic(button[i])) ) return false
  }
  return true
}

function compareVoicingArrays(array1=[], array2=[]){ // are the voicings identical
  const length = array1.length > array2.length ? array1.length : array2.length
  return array1.every( item => {
    for(let i = 0; i < length; i++){
      if(array2[i] && (item.label === array2[i].label && item.index === array2[i].index)){
        return true
      }
    }
    return false
  })
}

function createBassboardHighlightArray(chordArr){
  const voicings = searchBassboardMatrix(chordArr)
  return voicings.reduce((acc, curVal) => {
    for(let i=0; i < acc.length; i++){
      const alreadyHaveIt = compareVoicingArrays(acc[i], curVal)
      if(alreadyHaveIt) return acc
    }
    return [...acc, curVal]
  }, [])
}

function searchBassboardMatrix(chordArr){
  const bassButtonLabels = []
  const bassMatrix = getBassMatrix()
  for(let label in bassMatrix){//get all of the bassboard buttons that could possibly be in the chord
    for(let index = 0; index < bassMatrix[label].length; index++){
      if( isGeneratedChordWithinBassTriad(bassMatrix[label][index], chordArr) ) {
        bassButtonLabels.push({label, index})
      }
    }
  }
  return getBassVoicings(bassButtonLabels, chordArr)
}



//only one bass note at a time.
//if another chord button has the rest of the notes, or the bass note plus the rest of the notes, then add the voicing
//then if two chord buttons cover the notes in the chord, add the voicing
function getBassVoicings(bassNotes, chordArr){
  let bassBtns = []
  let triadBtns = []
  let voicings = []

  for(let i =0; i < bassNotes.length; i++){
    if(bassNotes[i]['label'] === 'root' || bassNotes[i]['label'] === 'third'){
      bassBtns.push(bassNotes[i])
    }else{
      triadBtns.push(bassNotes[i])
    }
  }

  for(let i = 0; i < bassBtns.length; i++){
    const toAdd = checkVoicingCompletion(bassBtns[i], triadBtns, chordArr )
    if( toAdd ) voicings = [...voicings,  ...toAdd]
  }

  if(voicings) return getSortedVoicings(voicings);
  // return [[...bassNotes]]

  /* returns something like:
  [
    [{label: "major", index: 5}, {label: "root", index: 5}],
    [{label: "major", index: 5}, {label: "third", index: 5}],
    [{label: "major", index: 5}, {label: "root", index: 6}],
  ]
  */
}

function getSortedVoicings(voicings){
  let sortedVoicings = [...voicings]
  .sort((a, b) => {//prioritize 'root' column over 'third' column
    return doesBassVoiceUseRootColumn(a);
  })

  .sort((a, b) => { //least number of buttons (arr length)
    return (a.length < b.length) ? -1 :
           (a.length > b.length) ? 1 : 0;
  })
  .sort((a, b) => {  //least amount of stretching (difference in index is least)
    const aa = getBassVoiceFingeringStretch(a)
    const bb = getBassVoiceFingeringStretch(b)
    return (aa < bb) ? -1 : 1;
  })
  return sortedVoicings
}




function checkVoicingCompletion(bassNote, triadBtns, chordArr, multiButtonCheck = false){
  const note = getBassNotesFromMatrix(bassNote.label, bassNote.index)[0]
  const noteInx = chordArr.indexOf(note)
  let matches = []


  for(let i = 0; i < triadBtns.length; i++){
    const triadNotes = getBassNotesFromMatrix(triadBtns[i].label, triadBtns[i].index)
    const chordMinusBass = [...chordArr]
          chordMinusBass.splice(noteInx, 1)

    if(multiButtonCheck){//triggered on recursion, to add two triads together before making comparison
      for(let j = 0; j < triadBtns.length; j++){
        const secondTriadNotes = getBassNotesFromMatrix(triadBtns[j].label, triadBtns[j].index)
        const combinedTriadNotes = removeRepeatNotes([...triadNotes, ...secondTriadNotes])
        if(compareGeneratedChordToBassTriad(combinedTriadNotes, chordMinusBass )){
          matches.push([ bassNote, triadBtns[i], triadBtns[j]])
        }
      }
    }else{
      if( compareGeneratedChordToBassTriad(triadNotes, chordMinusBass ) ){
        matches.push([bassNote, triadBtns[i]])
      }
    }
  }

  if (matches.length === 0 && !multiButtonCheck ) {
    matches = checkVoicingCompletion(bassNote, triadBtns, chordArr, true)
  }

  if(matches){
    matches = matches.filter( item => {
      return !isPhysicallyImpossibleFingering(item)
      return true
    })
  }

  return (matches && matches.length > 0) ? matches : null
}

export {
  compareVoicingArrays,
  getBassNotesFromMatrix,
  createBassboardHighlightArray,
}
