
function removeDeselectedKey(state, note){
  return state.filter( item => {
    return item.note === note.note && item.octave === note.octave ? false : true
  })
}

function removeDeselectedBassbutton(state, note){
  return state.filter( item => {
    return item.label===note.label && item.index === note.index ? false : true
  })
}


export { removeDeselectedKey, removeDeselectedBassbutton }
