import React from "react";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { userSelectChordGenerator, keyboardUI_toggle } from '../redux/actions'
import { addWindowResizeListener, resizeFooter } from '../utils/resize'

import { getVoicingFromRootAndChordType, getChordFromNotes,  getVoicingFromChordName } from '../utils/musicHelpers'
import { compareVoicingArrays } from '../utils/bassboardChordGenerator'
import HighlightNoteJSONDisplay from './options/HighlightNoteJSONDisplay'
import ChordSelectionContainer from './chordgenerator/ChordSelectionContainer'
import ChordDisplay from './chordgenerator/ChordDisplay'

;


class ChordGenerator extends React.Component{
  static propTypes = {
    highlightedKeyboardNotes: PropTypes.array,
    userSelectChordGenerator: PropTypes.func,
  }

  state = {
    //generator options
    octave: 3,
    KBvoicing:'', //0, inv1, inv2, inv3, allKeys, open0, open1
    BBvoicing:0,
    KBvoicingArray: [],
    BBvoicingArray: [],

    //music notes UI
    chordFromBassboard:'',
    chordFromKeyboard:'',
    chordFromAccordion:'',
    noteSelected: 'x',
    chordSelected: 'x',

    //layout options
    highlightNoteJSONDisplay: false,
  }

  handleNoteSelect = (value) => {
    this.setState({ noteSelected: value })
  }

  handleChordSelect = (value) => {
    this.setState({ chordSelected: value })
  }

  updateKeyboardFromGeneratorButton = () => {//updates keyboard and bassboard
    this.props.keyboardUI_toggle({value: false})

    try{
      const generatedChord = getVoicingFromRootAndChordType({
        note: this.state.noteSelected,
        chordType: this.state.chordSelected,
        octave: this.state.octave,
      })

      // console.log('generatedChord', generatedChord)
      this.setState({
        KBvoicingArray: generatedChord.keyboard,
        BBvoicingArray: generatedChord.bassboard,
        BBvoicing:0,

      }, () => this.setChordFromGenerator())

    }catch(err){
      this.setState({
        BBvoicing:0,
      })
      console.log('updateKeyboardFromGeneratorButton stopped');
    }
  }

  setChordFromGenerator = ( changeKB=true ) => {
    this.props.keyboardUI_toggle({value: false})
    const currentVoicing = this.state.BBvoicing < this.state.BBvoicingArray.length ? this.state.BBvoicing : 0
    const bassVoicings = this.state.BBvoicingArray.length > 0 ? this.state.BBvoicingArray[currentVoicing] : []
    this.props.userSelectChordGenerator({
      chord: [ ...(changeKB ? this.state.KBvoicingArray : this.props.highlightedKeyboardNotes), ...bassVoicings ]
    })
  }

  cycleBassVoicing = (value) => {
    this.props.keyboardUI_toggle({value: false})
    this.setState( prevState => {
      let newValue = prevState.BBvoicing + value
          newValue = (newValue > this.state.BBvoicingArray.length - 1) ? newValue = 0 : newValue
          newValue = (newValue < 0 ) ? newValue = this.state.BBvoicingArray.length - 1 : newValue
      return {BBvoicing: newValue}
  }, () => this.setChordFromGenerator(false))
  }

  getReducedBassNotes = () => {
    return this.props.highlightedBassNotes.reduce( (accum, curVal) => {
        // if(curVal && curVal.label && this.props.bassNoteMatrix[curVal.label][curVal.index] ){
          return  [...accum, ...this.props.bassNoteMatrix[curVal.label][curVal.index]]
        // }else{
        //   return accum
        // }
    }, [])
  }

  getReducedKBNotes = () => {
    return this.props.highlightedKeyboardNotes.map(({note}) => note)
  }

  updateChordGeneratorDisplay = () => { //based on keyboard and bassboard combined
    // console.log('updateChordGeneratorDisplay');
    const bassNotes = this.getReducedBassNotes()
    const kbNotes = this.getReducedKBNotes()

    const bassChord = getChordFromNotes(bassNotes)
    const kbChord = getChordFromNotes(kbNotes)
    this.setState({
      chordFromBassboard: bassChord ,
      chordFromKeyboard: kbChord,
      chordFromAccordion: getChordFromNotes([...bassNotes, ...kbNotes]),
    })
    //get voicings on bassboard press
    if(this.props.keyboardUI_active){
      if(bassChord && bassChord.length > 0){
        const generatedChord = getVoicingFromChordName({chordName: bassChord[0], octave: 4})

        this.setState({
          BBvoicingArray: generatedChord.bassboard,
        })
      }else{
        this.setState({
          BBvoicingArray: [],
          BBvoicing:0,
        })
      }


    }

    if(this.props.highlightedKeyboardNotes.length === 0 && this.props.highlightedBassNotes.length === 0){
      this.clearGeneratorSelection()
    }
  }

  clearGeneratorSelection = () => {
    this.setState({
      noteSelected: 'x',
      chordSelected: 'x',
    })
  }

  componentDidMount(){
    addWindowResizeListener()
  }

  componentDidUpdate(prevProps, prevState){
    //1. handleNoteSelect()
    //2. updateKeyboardFromGeneratorButton()
    //3. setChordFromGenerator
    //4. action setChordFromGenerator()
    //5. affects highlightedBassNotes
    //6. in response highlight update updateChordGeneratorDisplay
    const generatorButtonsUpdate = (prevState.noteSelected !== this.state.noteSelected || prevState.chordSelected !== this.state.chordSelected )

    if(generatorButtonsUpdate) this.updateKeyboardFromGeneratorButton()

    const bassNotesUpdate = prevProps.highlightedBassNotes !== this.props.highlightedBassNotes
    const keyboardNotesUpdate = prevProps.highlightedKeyboardNotes !== this.props.highlightedKeyboardNotes

    if(bassNotesUpdate || keyboardNotesUpdate)  this.updateChordGeneratorDisplay()

    const bassVoicing = this.state.BBvoicingArray[this.state.BBvoicing]
    const lastVoicing = prevState.BBvoicingArray[prevState.BBvoicing]

    const isDifferentBassButton = this.state.BBvoicing === prevState.BBvoicing  && ( this.state.BBvoicingArray.length < this.state.BBvoicing - 1 || !compareVoicingArrays(bassVoicing, lastVoicing) )

    if( isDifferentBassButton ){
      this.setState({
        BBvoicing: 0,
      })
    }
    resizeFooter();
  }

  render(){
    return(
      <div className='ChordGenerator'>

        { (this.state.chordFromBassboard  || this.state.chordFromKeyboard ) &&
        <ChordDisplay
          chordFromBassboard={this.state.chordFromBassboard}
          optionData={this.state.BBvoicingArray}
          optionIndex={this.state.BBvoicing}
          onNext={() => this.cycleBassVoicing(1)}
          onPrev={() => this.cycleBassVoicing(-1)}
          chordFromAccordion={this.state.chordFromAccordion}
          chordFromKeyboard={this.state.chordFromKeyboard}
         />
        }

        { this.state.highlightNoteJSONDisplay &&
        <HighlightNoteJSONDisplay highlightedBassNotes={this.props.highlightedBassNotes} highlightedKeyboardNotes={this.props.highlightedKeyboardNotes} />
        }

        { this.props.showChordGenerator &&
        <ChordSelectionContainer
          handleNoteSelect={(value) => this.handleNoteSelect(value)}
          noteSelected={this.state.noteSelected}

          handleChordSelect={(value) => this.handleChordSelect(value)}
          chordSelected={this.state.chordSelected}
        />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    screenSizeVer: state.screenSizeVer,
    showChordGenerator: state.showChordGenerator,
    highlightedKeyboardNotes: state.highlightedKeyboardNotes,
    highlightedBassNotes: state.highlightedBassNotes,
    bassNoteMatrix: state.bassNoteMatrix,
    keyboardUI_active: state.keyboardUI_active,
  }
}

const mapActionToProps = {
  userSelectChordGenerator,
  keyboardUI_toggle,
}

export default connect(mapStateToProps, mapActionToProps)(ChordGenerator)
