import React from "react";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {  clearBassButtons, clearKeyboardButtons } from '../../redux/actions'


const ResetButton = (props) => {

  const {
    type,
    className='',
    highlightedBassNotes,
    highlightedKeyboardNotes,
    clearBassButtons,
    clearKeyboardButtons,
  } = props


  function checkDisplay(){
    if(type=='bass'){
      return highlightedBassNotes && highlightedBassNotes.length ?  true : false
    }else{
      return highlightedKeyboardNotes && highlightedKeyboardNotes.length ? true : false
    }
  }

  function getClickFunction(){
    if(type === 'bass'){
      return function(){

        clearBassButtons()
      }
    }else{
      return function(){

        clearKeyboardButtons()
      }
    }
  }

  function getBaseClasses(){
    if(type === 'bass'){
      return 'button clearBassButtons '
    }else{
      return 'button clearKeyboardButtons '
    }
  }

  return(
    <>
    {checkDisplay()
      ?
      <div className={getBaseClasses() + className} onClick={getClickFunction()}></div>
      :
      <div className={getBaseClasses() + className + ' hidden-placeholder'} ></div>
    }
    </>
  )
}

ResetButton.propTypes = {
  type: PropTypes.string,
}
const mapStateToProps = state => {
  return{
    highlightedKeyboardNotes: state.highlightedKeyboardNotes,
    highlightedBassNotes: state.highlightedBassNotes,
  }
}

const mapActionsToProps = {
  clearKeyboardButtons: clearKeyboardButtons,
  clearBassButtons: clearBassButtons,
}

export default connect(mapStateToProps, mapActionsToProps)(ResetButton)
