import React from "react";
import PropTypes from 'prop-types'

class OptionButton extends React.PureComponent {


  render(){
    return (


      <div
        className={this.props.styleClass + (this.props.selected ? ' buttonSelectedHighlight ': '')}
        onClick={() => this.props.onClick((typeof this.props.value !== "undefined") ? this.props.value : this.props.index)}
      >
        <div className='buttonTitle'> {this.props.title} </div>
      </div>

    )
  }
}

OptionButton.propTypes = {
  title: PropTypes.string,
  // value: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
}
export default OptionButton
