import React from "react";

class ChordDisplay extends React.Component {

  state = {
    bassDisplay: 'chord',
    middleDisplay: 'chord',
    keyboardDisplay: 'chord',
  }

  render(){

    return(

      <div className="chordGeneratorDisplay">


        <div className="chordFromBassboard"
          onMouseOver={ () => this.setState({ bassDisplay: 'over'}) }
          onMouseOut={ () => this.setState({ bassDisplay: 'chord'}) }
        >
          {
            this.state.bassDisplay === 'over' ?
            <div className="chordFrom_hoverOverlay">{"Chord generated from left side" }</div> :
            this.props.chordFromBassboard && this.props.chordFromBassboard.join(', ')
          }
        </div>


        <div className="cyclingOption">
          {this.props.optionData.length > 1 &&
            <React.Fragment>
              <div className="voicingAdjustLeft" onClick={this.props.onPrev}></div>
              <div className="voicingCount">{this.props.optionIndex+1 +'/'+ this.props.optionData.length}</div>
              <div className="voicingAdjustRight" onClick={this.props.onNext}></div>
            </React.Fragment>
          }
        </div>

        <div className="chordFromAccordion"
          onMouseOver={ () => this.setState({ middleDisplay: 'over'}) }
          onMouseOut={ () => this.setState({ middleDisplay: 'chord'}) }
        >
          {this.props.chordFromAccordion && this.props.chordFromAccordion.join(', ')}
          {
            this.state.middleDisplay === 'over' &&
             <div className="chordFrom_hoverOverlay">{"Left + right sides together"} </div>
          }
        </div>

        <div className="chordFromKeyboard"
          onMouseOver={ () => this.setState({ keyboardDisplay: 'over'}) }
          onMouseOut={ () => this.setState({ keyboardDisplay: 'chord'}) }
        >
          {
            this.state.keyboardDisplay === 'over' ?
            <div className="chordFrom_hoverOverlay">{"Chord generated from right side" }</div> :
            this.props.chordFromKeyboard && this.props.chordFromKeyboard.join(', ')
          }
        </div>

      </div>
    )
  }
}

export default ChordDisplay
