import React from "react";
import PropTypes from 'prop-types'

import OptionButtonRow from './OptionButtonRow'

import { stradellaTypes } from '../../utils/bassboardLayout'
import { keyboardTypes } from '../../utils/keyboardLayout'


class LayoutOptions extends React.Component {
  render(){
    return (
      <div className="accordionLayoutOptionsContainer ">
        <OptionButtonRow
          headerText = {<div style={{color:"#ccc"}}>Bass button Layout:</div>}
          list={stradellaTypes}
          keyOfItemTitle={'version'}
          keyOfItemValue={undefined}
          containerClass={'accordionLayoutOption'}
          itemClass={'optionButtonAccordionLayout centerContent'}
          onItemClick={(index) => this.props.updateBassboardLayoutInx(index)}
          selected={stradellaTypes[this.props.bassboardInx].version}
        />

        <OptionButtonRow
          headerText = {<div style={{color:"#ccc"}}>Keyboard Layout:</div>}
          list={keyboardTypes}
          keyOfItemTitle={'version'}
          keyOfItemValue={undefined}
          containerClass={'accordionLayoutOption'}
          itemClass={'optionButtonAccordionLayout centerContent'}
          onItemClick={(index) => this.props.updateKeyboardLayoutInx(index)}
          selected={keyboardTypes[this.props.keyboardInx].version}
        />
      </div>
    )
  }
}

LayoutOptions.propTypes = {
  bassboardInx: PropTypes.number,
  updateBassboardLayoutInx: PropTypes.func,
  keyboardInx: PropTypes.number,
  updateKeyboardLayoutInx: PropTypes.func,
}

export default LayoutOptions
