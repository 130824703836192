import {CHROMATIC} from './musicHelpers'

function createKeyboardLayoutArray(startingPoint={note:"f", octave:2}, length=12, perspective){
  let newKeyboard = []
  let octave = startingPoint.octave
  let chrom_const_inx = CHROMATIC.indexOf(startingPoint.note)
  const addToArr = perspective === 'mirror' ? 'push' : 'unshift'


  for(let i = length; i > 0; i-- ){
    newKeyboard[addToArr]({
      note: CHROMATIC[chrom_const_inx],
      octave: octave
    })
    chrom_const_inx ++
    if(chrom_const_inx > 11) {
      chrom_const_inx = 0
      octave ++
    }
  }
  return newKeyboard
}

const keyboardTypes = [
  {
    keys: 25,
    version: '25 keys, C to C',
    startingPoint: {note: 'c', octave: 3},
    columns: [],
    description:'',
  },
  {
    keys: 26,
    version: '26 keys, B to C',
    startingPoint: {note: 'b', octave: 3},
    columns: [],
    description:'',
  },
  {
    keys: 30,
    version: '30 keys, G to C',
    startingPoint: {note: 'g', octave: 3},
    columns: [],
    description:'',
  },
  {
    keys: 34,
    version: '34 keys, G to E',
    startingPoint: {note: 'g', octave: 3},
    columns: [],
    description:'',
  },
  {
    keys: 37,
    version: '37 keys, F to F',
    startingPoint: {note: 'f', octave: 2},
    columns: [],
    description:'',
  },
  {
    keys: 41,
    version: '41 keys, F to A',
    startingPoint: {note: 'f', octave: 2},
    columns: [],
    description:'41 keys, F to A',
  },
]

export {
  keyboardTypes,
  createKeyboardLayoutArray,
}
