import React from 'react';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

class PianoKey extends React.PureComponent {
  state = {
    blackKey: false,
    displayNote: true,
  }

  setCSS = () =>{
    let classes = "PianoKey"
    classes += (this.state.blackKey ? " blackKey" : " whiteKey")
    classes += (this.props.selected ? "Highlight" : "")

    if(this.props.perspectiveOption === 'mirror'){
      classes += ( this.props.note === "eb" || this.props.note === "bb") ?  " adjustBlackKeyDown" : ""
      classes += ( this.props.note === "gb" || this.props.note === "db") ?  " adjustBlackKeyUp" : ""
    }else{
      classes += ( this.props.note === "eb" || this.props.note === "bb") ?  " adjustBlackKeyUp" : ""
      classes += ( this.props.note === "gb" || this.props.note === "db") ?  " adjustBlackKeyDown" : ""
    }

    if(this.props.smallKeyboardOption){
      classes += " Small "
    }

    return classes
  }

  onClick = () =>{
    this.props.handleKeyboardNoteSelect({ note: this.props.note, octave: this.props.octave }, this.props.selected )
  }

  handleNewKeyboard = () => {
    if(this.props.note.length > 1){
      this.setState({ blackKey: true})
    }else{
      this.setState({ blackKey: false})

    }
  }

  upperCaseFirstLetter(str){
    return str[0].toUpperCase() + (!str[1] ? '' : str[1])
  }

  componentDidMount(){
    this.handleNewKeyboard()
  }

  componentDidUpdate(prevProps, prevState){
   if(this.props.note !== prevProps.note || (this.props.octave !== prevProps.octave)){
     this.handleNewKeyboard()
   }
  }

  render(){
    return(



      <div className={this.setCSS()} onClick={() => this.onClick()}>
        {this.props.displayNote &&
        this.upperCaseFirstLetter(this.props.note ) + this.props.octave
       }
      </div>

    )
  }

}

PianoKey.propTypes = {
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  note: PropTypes.string,
  octave: PropTypes.number,
}

const mapStateToProps = (state) => {
  return{
    perspectiveOption: state.perspectiveOption,
    smallKeyboardOption: state.smallKeyboardOption,
  }
}

export default connect(mapStateToProps)(PianoKey)
