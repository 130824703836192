import React from "react";
import PropTypes from 'prop-types'
import {connect} from "react-redux"

import OptionButtonRow from '../options/OptionButtonRow'
import ResetButton from './ResetButton'
import {scrollEnable, scrollDisable} from '../../utils/scrollDisable'


const CHROMATIC_SELECTION = [ {title:'A', value:'a'},     {title:'Bb/A#', value:'bb'},{title:'B', value:'b'},     {title:'C', value:'c'},
                              {title:'Db/C#', value:'db'},{title:'D', value:'d'},     {title:'Eb/D#', value:'eb'},{title:'E', value:'e'},
                              {title:'F', value:'f'},     {title:'F#/Gb', value:'gb'},{title:'G', value:'g'},     {title:'G#/Ab', value:'ab'},
                            ]

class ChordSelectionContainer extends React.Component {

  state = {
    generatorMenuOpen: false,
    chordOptions: [ { title: 'Major', value: 'M' },{ title: 'minor', value: 'm' },
                    { title: '7', value: [0, 4, 10] },
                    { title: 'dim7', value: [0, 3, 9] },
                    { title: 'dim', value: 'dim' },
                      //4 note

                    { title: '6', value: '6' }, { title: 'm6', value: 'm6' }, { title: '7[4tone]', value: '7' },

                    { title: 'maj7', value: 'maj7' },
                    { title: 'm7', value: 'm7' },{ title: 'dim7[4tone]', value: 'dim7' },{ title: 'min7b5', value: 'min7b5' },
                    { title: '7#11', value: [0, 4, 6, 10] },
                    { title: '7b9', value: [0, 1, 4, 10] },
                    { title: 'm(maj7)', value: 'm(maj7)' }, { title: 'aug7', value: 'aug7' },
                    { title: 'add9', value: 'add9' }, { title: 'add11', value: 'add11' }, { title: '-add9', value: '-add9' }, { title: '-add11', value: '-add11' },
                      // 5/6 note
                    { title: '6add9', value: [0, 2, 4, 9] },
                    { title: '9', value: '9' },{ title: 'maj9', value: 'maj9' },{ title: 'm9', value: 'm9' },
                    { title: '11', value: '11' },{ title: 'maj11', value: 'maj11' },{ title: 'm11', value: 'm11' },
                    { title: '13', value: '13' },{ title: 'maj13', value: 'maj13' },{ title: 'm13', value: 'm13' },
                  ]
  }

  handleOpenGeneratorMenu = () => {
    this.setState( prevState => {
      return { generatorMenuOpen: !prevState.generatorMenuOpen }
    })
  }

  handleScrollDisable = () => {
    scrollDisable()
  }

  handleScrollEnable = () => {
    scrollEnable()
  }

  handleScrollReset(){
    for(let item in document.getElementsByClassName('scrollarea')){
      try{
        item.scrollTop = 0;
        item.scrollLeft = 0;
      }catch(e){}
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.screenSizeVer === 2 && prevProps.screenSizeVer === 3){
      this.setState({
        noteMenuOpen: false,
        chordMenuOpen: false,
      })
      this.handleScrollReset()

    }else if(this.props.screenSizeVer === 3 && prevProps.screenSizeVer <= 2){
      this.handleScrollReset()
    }
  }

  render(){
    const condenseGeneratorMenu = this.props.screenSizeVer < 3 && !this.state.generatorMenuOpen
    const mobileSize = this.props.screenSizeVer < 3

    return (
      <div className={`ChordSelectionContainer`}>
        { condenseGeneratorMenu ?
          <div className="consensedHeader">


            <ResetButton type={'bass'}/>
            <div className={"button openGeneratorMenu "} onClick={this.handleOpenGeneratorMenu}>Chord Finder</div>
            <ResetButton type={'keyboard'}/>


          </div>

          :

          <React.Fragment>

            { mobileSize ?
              <div className={mobileSize ? "consensedHeader" : ''}>
                  <ResetButton type={'bass'} className={"open"}/>
                  <div className={"chordSelectionHeader button"} onClick={this.handleOpenGeneratorMenu}>
                    Close
                  </div>
                  <ResetButton type={'keyboard'} className={"open"}/>
              </div>
              :
              <div className={"chordSelectionHeader " + (this.props.screenSizeVer < 3 ? "button" : "")} onClick={this.handleOpenGeneratorMenu}>
                {this.props.screenSizeVer < 3 ? "Close" : "Select a chord:"}
              </div>
            }

            <div className={"selectionContainerNotes " }>
                <OptionButtonRow
                  list={CHROMATIC_SELECTION}
                  keyOfItemTitle={'title'}
                  keyOfItemValue={'value'}
                  containerClass={'chooseNoteOptionContainerOpen'}
                  scrollContentClass={'chooseNoteOptionContent'}
                  itemClass={'optionButtonNotes '}
                  onItemClick={(value) => this.props.handleNoteSelect(value)}
                  selected={this.props.noteSelected}
                  horizontal={this.props.screenSizeVer !== 3 }
                />
            </div>

            <div className={ "selectionContainerChords "}>
              <OptionButtonRow
                list={this.state.chordOptions}
                keyOfItemTitle={'title'}
                keyOfItemValue={'value'}
                containerClass={'chooseChordOptionContainerOpen'}
                scrollContentClass={'chooseChordOptionContent'}
                itemClass={'optionButtonChords '}
                onItemClick={(value) => this.props.handleChordSelect(value)}
                selected={this.props.chordSelected}
                horizontal={this.props.screenSizeVer !== 3 }

              />
            </div>
          </React.Fragment>
        }
      </div>
    )
  }
}

ChordSelectionContainer.propTypes = {
  noteSelected: PropTypes.string,
  handleNoteSelect: PropTypes.func,
  // chordSelected: PropTypes.string,
  handleChordSelect: PropTypes.func,

}

const mapStateToProps = (state) => {
  return {
    screenSizeVer: state.screenSizeVer,
  }
}


export default connect(mapStateToProps)(ChordSelectionContainer)
