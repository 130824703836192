import {combineReducers} from 'redux'
import {types} from './actions'
import {removeDeselectedKey, removeDeselectedBassbutton} from './reducerFunctions'

const mainReducer = combineReducers ({
  screenSizeVer: updateScreenSizeReducer,

  showOptionScreen: showOptionScreenReducer,
  showChordGenerator: toggleShowChordGeneratorReducer,
  perspectiveOption: togglePerspectiveReducer,// 'player' or 'mirror'
  showNotesOnButtons: toggleNotesOnButtonsReducer,
  smallKeyboardOption: toggleSmallKeyboardOptionReducer,

  showHelpScreen: toggleHelpScreenReducer,
  showAboutScreen: toggleAboutScreenReducer,

  keyboardNotesArray: keyboardNotesArrayReducer,
  bassNoteMatrix: bassNoteMatrixReducer,

  //music + chord UI parameters
  highlightedKeyboardNotes: highlightedKeyboardReducer,
  highlightedBassNotes: highlightedBassReducer,
  keyboardUI_active: keyboardUI_toggleReducer
})

function updateScreenSizeReducer(state=0, {type, value}){
  switch(type){
    case types.UPDATE_SCREEN_SIZE:
      return value;

    default:
      return state
  }
}

//layout
function showOptionScreenReducer(state=false, {type, value}){
  switch(type){
    case types.TOGGLE_SHOW_OPTION_SCREEN:
      return value;

    default:
      return state
  }
}
function toggleShowChordGeneratorReducer(state=true, {type, value}){
  switch(type){
    case types.TOGGLE_SHOW_CHORD_GENERATOR:

      return value
    default:
      return state
  }
}

function togglePerspectiveReducer(state='player', {type, value}) {
  switch(type){
    case types.TOGGLE_PERSECTIVE:
      return value
    default:
      return state
  }
}
function toggleNotesOnButtonsReducer(state=true, {type, value}) {
  switch(type){
    case types.TOGGLE_NOTES_ON_BUTTONS:
      return value
    default:
      return state
  }
}
function toggleSmallKeyboardOptionReducer(state=true, {type, value}) {
  switch(type){
    case types.TOGGLE_SMALL_KEYBOARD_OPTION:
      return value
    default:
      return state
  }
}

function toggleHelpScreenReducer(state=false, {type, value}) {
  switch(type){
    case types.TOGGLE_HELP_SCREEN:
      return value
    default:
      return state
  }
}
function toggleAboutScreenReducer(state=false, {type, value}) {
  switch(type){
    case types.TOGGLE_ABOUT_SCREEN:
      return value
    default:
      return state
  }
}


function keyboardNotesArrayReducer(state=[], {type, value} ){
  switch(type){
    case types.KEYBOARD_LAYOUT_UPDATE:
      return value;

    default:
      return state
  }
}

function bassNoteMatrixReducer(state={}, {type, value} ){
  switch(type){
    case types.BASS_LAYOUT_UPDATE:
      return value
    default:
      return state
  }
}


function highlightedKeyboardReducer(state=[], {type, note, remove=true, chord} ) {//'note' is an array for the GENERATOR
  // console.log('highlightedKeyboardNotes', note);
  switch(type){
    case types.KEYBOARD_NOTE_SELECT:
      if(remove){
        return removeDeselectedKey(state, note)
      }else{
        return [...state, note]
      }
    case types.GENERATOR_CHORD_SELECT:
      return chord.reduce((accum, item) => {
        if(item.note && item.octave){
          return [...accum, {note: item.note, octave: item.octave}]
        }else{
          return accum
        }
      }, [])
    case types.CLEAR_KEYBOARD_BUTTONS:
      return []

    default:
      return state
  }
}

function highlightedBassReducer(state=[], {type, note, remove, chord}  ) {//by bass table index
  switch (type) {
    case types.BASSBOARD_NOTE_SELECT:
      if(remove){
        return removeDeselectedBassbutton(state, note)
      }else{
        return [...state, note]
      }
    case types.GENERATOR_CHORD_SELECT:
      return chord.reduce((accum, item) => {
        if(item.label){
          return [...accum, {label: item.label, index: item.index}]
        }else{
          return accum
        }
      }, [])
    case types.CLEAR_BASS_BUTTONS:
      return []
    default:
      return state
  }
}
function keyboardUI_toggleReducer(state=false, {type, value}){
  switch(type){
    case types.KEYBOARD_UI_TOGGLE:
      return value
    default:
      return state
  }
}

export default mainReducer
