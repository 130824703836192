import {updateScreenSize} from '../redux/actions'
import {store} from '../redux/store'

let curSizeVer = 0;

function resizeFooter(evt){
  let h = document.getElementsByClassName('HoveringFooter')[0].offsetHeight
  document.getElementsByClassName('AccordionContainer')[0].style.paddingBottom = h+100+'px'
}

function addWindowResizeListener(){
  window.onresize = resizeFooter
  window.onresize = checkScreenSizeState
  resizeFooter()
  checkScreenSizeState()
}

function checkScreenSizeState(){
  const width = window.innerWidth
  let nextSizeVer = (width <= 660) ? 1 :
                    (width <= 1060) ? 2 : 3

  if( curSizeVer !== nextSizeVer ){
    store.dispatch( updateScreenSize({value:nextSizeVer}) )
    return nextSizeVer
  }
}

export { addWindowResizeListener, resizeFooter }
