import React from "react";
import PropTypes from 'prop-types';


const SwitchButton = (props) => {
  const positionStyle = (props.index === 0 ? "First" : props.index === props.arr.length -1 ? "Last" : "" )
  return (
    <div
      className={'SwitchButton '+ (props.selected ? 'SwitchButtonSelected' : '') + " " + positionStyle}
      onClick={props.onClick}
    >
      {props.title}
    </div>
  )
}

class SwitchButtonArray extends React.Component {

  render(){
    return(
      <div className="SwitchButtonArray">
        {this.props.choices.map( (item, inx, arr) => {
          return (
            <SwitchButton
              key={inx}
              index={inx}
              arr={arr}
              title={item.label}
              selected={item.value === this.props.selected}
              onClick={() => this.props.handleSelection(item.value)}
            />
          )
        })}
      </div>
    )
  }
}

SwitchButtonArray.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  handleSelection: PropTypes.func,
  choices: PropTypes.array,
}

export default SwitchButtonArray
