import React from "react";
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { userSelectBassboard, keyboardUI_toggle } from '../redux/actions'

// import {getEnharmonic} from '../chordFinder/chordFinder'

import BassButton from './BassButton'

const BassColumn = (props) => {

  return(
    <div className={"bassColumn" + (props.smallKeyboardOption ? " Small":"")} style={{paddingTop: props.paddingTop}}>
      {props.columnData.map( (notes, i) => {
        return (
          <BassButton
              key={i}
              label={props.label}
              index={i}
              notes={notes}
              handleBassSelect={(note, selected) => props.handleBassSelect(note, selected)}
              selected={props.isButtonSelected({label:props.label, index:i})}
              displayNote={props.displayNote}
              smallKeyboardOption={props.smallKeyboardOption}
          />
        )
      })}
    </div>
  )
}

class BassButtonboard extends React.Component {
  state = {}

  isButtonSelected = (note) =>{
    return this.props.highlightedBassNotes.some( item => {
      return item.label === note.label && item.index === note.index ? true : false
    })
  }

  handleBassSelect = (note, selected) => {
    const remove = selected
    this.props.userSelectBassboard({note, remove})
    this.props.keyboardUI_toggle({value: true})

  }

  columnSorter(a, b){
    const order = ['third', 'root', 'major', 'minor', '7', 'dim']
    if(order.indexOf(a) < order.indexOf(b)){
        return 1
    }else{
      return -1
    }
  }

  render() {
    const {bassNoteMatrix} = this.props

    return (
      <div className={"BassButtonboard" +( this.props.smallKeyboardOption ? " Small":"")}>
      {
        Object.keys(bassNoteMatrix).sort(this.columnSorter).map( (item, i, ar) => {
          return <BassColumn
                  key={i}
                  columnData={bassNoteMatrix[item]}
                  label={item}
                  paddingTop={ this.props.perspectiveOption === 'mirror' ? (i * 12): (ar.length - i) * 12}

                  handleBassSelect={this.handleBassSelect}
                  isButtonSelected={this.isButtonSelected}
                  displayNote={this.props.showNotesOnButtons}
                  smallKeyboardOption={this.props.smallKeyboardOption}
                />
          })
        }
      </div>
    )
  }
}

BassButtonboard.propTypes = {
  perspectiveOption: PropTypes.string,
  bassNoteMatrix: PropTypes.object,
  highlightedBassNotes: PropTypes.array,
}
const mapStateToProps = (state) => ({
  highlightedBassNotes: state.highlightedBassNotes,
  perspectiveOption: state.perspectiveOption,
  showNotesOnButtons: state.showNotesOnButtons,
  smallKeyboardOption: state.smallKeyboardOption

})

const mapActionToProps = {
  userSelectBassboard,
  keyboardUI_toggle,
}
export default connect(mapStateToProps, mapActionToProps)(BassButtonboard)
