import React from "react";
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import BassButtonboard from './BassButtonboard'
import BellowsSection from './BellowsSection'
import PianoKeyboard from './PianoKeyboard'
import ResetButton from './chordgenerator/ResetButton'


const AccordionContainer = (props) => {

  const {
    perspectiveOption,
    keyboardNotesArray,
    bassNoteMatrix,
    highlightedNotes,
    screenSizeVer,
  } = props


  const mobileSize = screenSizeVer < 3


  return(
    <div className='AccordionContainer'>
      <div className='BassSection'>
        <BassButtonboard
          perspectiveOption={perspectiveOption}
          bassNoteMatrix={bassNoteMatrix}
          highlightedNotes = {highlightedNotes}

        />
      </div>
      <BellowsSection />

      <div className='KeyboardSection'>
        <div className={perspectiveOption === 'mirror'? 'mirrorKeyboardLogo':'keyboardLogo'}></div>
        <PianoKeyboard
          perspectiveOption={perspectiveOption}
          keyboardNotesArray={keyboardNotesArray}
        />
      </div>

      { !mobileSize &&
        <div className="containerForClearButtons">
          <ResetButton type={'bass'}/>
          <ResetButton type={'keyboard'}/>
        </div>
      }
    </div>
  )
}

AccordionContainer.propTypes = {
  keyboardNotesArray: PropTypes.array,
  userSelectKeyboard: PropTypes.func,

  bassNoteMatrix:PropTypes.object,
  highlightedNotes: PropTypes.array,
}

const mapStateToProps = (state) => {
  return {
    perspectiveOption: state.perspectiveOption,
    keyboardNotesArray: state.keyboardNotesArray,
    bassNoteMatrix: state.bassNoteMatrix,

    highlightedNotes: state.highlightedNotesTheory,

    screenSizeVer: state.screenSizeVer,

  }
}

export default connect(mapStateToProps)(AccordionContainer)
