import ReactGA from 'react-ga'

const options = {}

const trackPage = (page) => {
  ReactGA.set({
    page,
    ...options
  })
  ReactGA.pageview(page)
}

let currentPage = ''



export const googleAnalytics = store => next => action => {

  if(action.type === "CLEAR_BASS_BUTTONS"){

    ReactGA.event({
      category: 'button',
      action: 'reset_bassboard',
      label: 'reset',
    });
  }
  if(action.type === "CLEAR_KEYBOARD_BUTTONS"){

    ReactGA.event({
      category: 'button',
      action: 'reset_keyboard',
      label: 'reset',
    });
  }

// // leaving here because i'm adding either routes or static pages at some point
  // if (action.type === '@@router/LOCATION_CHANGE') {
  //   const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`
  //
  //   if (currentPage !== nextPage) {
  //     currentPage = nextPage
  //     trackPage(nextPage)
  //   }
  // }

  return next(action)
}
