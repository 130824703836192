import React from 'react';
import {Provider} from 'react-redux'
import {store} from './redux/store'
import ReactGA from 'react-ga';

import AccordionContainer from './components/AccordionContainer'
import ChordGenerator from './components/ChordGenerator'
import OptionBar from './components/options/OptionBar'
import HelpScreen from './components/options/HelpScreen'
import AboutScreen from './components/options/AboutScreen'
import OptionMenu from './components/options/OptionMenu'
import './styles/css/App.css';
const appData = require('../package.json');
ReactGA.initialize('UA-143153454-1');


class App extends React.Component {

  render() {
    return (

      <Provider store={store}>
        <div className="App">
          <div className="appVersion">v{appData.version}</div>
          <div className="appScrollSwrapper">
            <AccordionContainer />
            <div className='HoveringFooter'>
              <ChordGenerator />
            </div>
            <OptionMenu />
            <HelpScreen />
            <AboutScreen />
            <OptionBar />
          </div>
        </div>
      </Provider>
    );
  }
}

export default App;
