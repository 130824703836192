import {getEnharmonic} from '../chordFinder/chordFinder'
import {CHROMATIC} from './musicHelpers'
import {store} from '../redux/store'

function createKeyboardHighlightArray(chordArr, startingOctave){

  const keyboardNotesArray = store.getState().keyboardNotesArray
  let chromatic_position = 0;
  let last_position = chromatic_position
  // let octave = startingOctave // disbled until dynamic keyboard inversion implementation
  let octave = keyboardNotesArray.reduce( (accum, curVal) => {
    let lowest = accum[0] ? accum[0].octave : 7
    if(curVal.note === chordArr[0] && curVal.octave < lowest) return [curVal]
    return accum
  },[])[0].octave

  return chordArr.map( (item) => {
    item = getEnharmonic(item)
    chromatic_position = CHROMATIC.indexOf(item)
    if(chromatic_position < last_position) octave ++
    last_position = chromatic_position
    return {note: item, octave: octave}
  })
}

function getOpenVoicing(arr){
  //search the KB layout for a note matching the arr, but only return every other key found, so notes are skipped
}

export {
  createKeyboardHighlightArray,
  getOpenVoicing
}
