import teoria from 'teoria';
import { chordFinder, getEnharmonic } from '../chordFinder/chordFinder'
import { createBassboardHighlightArray } from './bassboardChordGenerator'
import { createKeyboardHighlightArray } from './keyboardChordGenerator'

const CHROMATIC = ['a', 'bb', 'b', 'c', 'db', 'd', 'eb', 'e', 'f', 'gb', 'g','ab']


function removeRepeatNotes(arr){
  return arr.reduce((accum, curVal) => {
    const val = getEnharmonic(curVal)
    return accum.includes(val) ? accum : [...accum, val]
  }, [])
}


function getNotesFromChord({note, chordType, octave}){//saving for later
  const noteObj = teoria.note(note+octave)
  const chordObj = teoria.chord(noteObj, chordType)
  return chordObj.simple()
}

function getChordFromNotes(arr, verboseChords = false){//input ['a', 'c', 'e']
  return chordFinder(removeRepeatNotes(arr))
}

function getFormattedNoteName(note, returnAllEnharmonic=false){
  if(note.length > 1 && returnAllEnharmonic){
    const also = {
      'eb':'d#',
      'gb':'f#',
      'ab':'g#',
      'bb':'a#',
      'db':'c#',
    }
    return ( format(note) + '/' + format(also[note.toLowerCase()]) );
  }else{
    return format(note);
  }
  function format(_note){

    return _note[0].toUpperCase() + ( (_note.length > 1) ? _note[1] : '');
  }

}

function getFormattedChordName(note, chordType ){//////???????????
  return getFormattedNoteName(note) + ' ' + chordType
}

function getNotesFromChromIntervals(note, arr){//input (F7 chord): f, [0, 4, 7, 10]
  const chromatic = [...CHROMATIC]
  const spliced = chromatic.splice([...CHROMATIC].indexOf(getEnharmonic(note)))
  const  keyedChromatic = [...spliced, ...chromatic]
  return arr.map( item => keyedChromatic[item] )
}

function getVoicingsFromChordArray({chordArr, octave}){
  const acchelper_kbNotes = createKeyboardHighlightArray(chordArr, octave)
  const acchelper_bassNotes = createBassboardHighlightArray(chordArr)
  return { keyboard: acchelper_kbNotes, bassboard: acchelper_bassNotes }

}

function getVoicingsFromTeoriaChord({chordObj, octave}){
  const chordArr = chordObj.simple()

  return getVoicingsFromChordArray({chordArr})
}

function getVoicingFromRootAndChordType({note, chordType, octave}){

  if(typeof chordType === 'object'){
    const chordArr = getNotesFromChromIntervals(note, chordType)
    return getVoicingsFromChordArray({chordArr})
  }
  if(note && octave){
    const noteObj = teoria.note(note+octave)
    const chordObj = teoria.chord(noteObj, chordType)
    return getVoicingsFromTeoriaChord({chordObj, octave})
  }
  return []
}

function getVoicingFromChordName({chordName, octave}){//used for bass only atm
  const chordObj = teoria.chord(chordName)
  return getVoicingsFromTeoriaChord({chordObj, octave})
}

export {
  CHROMATIC,
  removeRepeatNotes,
  getChordFromNotes,
  getVoicingFromRootAndChordType,
  getFormattedNoteName,
  getFormattedChordName,
  getVoicingFromChordName,

}
