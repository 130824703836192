import React from "react";
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { toggleAboutScreen } from '../../redux/actions'
import LightboxOverlay from './LightboxOverlay'
const appData = require('../../../package.json');


const AboutScreen = ({showAboutScreen, toggleAboutScreen}) => {

    return(
      <React.Fragment>
       {showAboutScreen &&
      <LightboxOverlay
        onClose={() => toggleAboutScreen({value:false})}
        header={"About"}
      >

        <div style={{fontSize: 14, textAlign:'left'}} className="OptionMenu">

          <span className={"enableTextSelect"} style={{textAlign:'center'}}>
            Current version: v{appData.version}<br/>
            by Zach Rodis - 2019<br/>
            <h3>Contact</h3>
            Please send feedback or comments to: <br/>
            <span className="firstSentenceHighlight "> accordionhelper (at) gmail.com </span>
          </span>
          <br/>
          <h3>Updates</h3>
          More updates coming soon!
          <p>
            Version 0.4.0 -- August 2019:<br/>
            • Added reset buttons so bass and keyboard sides can be reset individually.<br/>
            • Some visitor metrics to see how much the new buttons get used.<br/>
            • Fixed a chord generator bug where some voicings weren't being found.<br/>
            • Updated some dependencies<br/>
            • Some other really small stuff I forgot to write down<br/>

          </p>
          <p>
            Version 0.3.4 -- May 2019:<br/>
            • Adjusted the chord generator on the bassboard. It now prioritizes easier fingering.<br/>
            • Chords added: 6add9, 7#11, 7b9 <br/>
            • changed 7 and dim7 to 3 note chords, and added separate 7(4note) and dim7(4note) chord generator buttons.<br/>
            • Added the ability to save Options. Made the Options buttons a little more clear.<br/>

          </p>
          <p>
            Version 0.3.3 -- April 2019:<br/>
            • Fixed scroll bugs on Android.
          </p>


        </div>

      </LightboxOverlay>
      }
      </React.Fragment>
    )

}

AboutScreen.propTypes = {
  showAboutScreen: PropTypes.bool,
  toggleAboutScreen: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    showAboutScreen: state.showAboutScreen,
  }
}
const mapActionToProps = {
  toggleAboutScreen

}

export default connect(mapStateToProps, mapActionToProps)(AboutScreen)
