

function isPhysicallyImpossibleFingering(voicing){
  for(let i=0; i < voicing.length; i++){
    const button = voicing[i]
    for(let j=0; j < voicing.length; j++){
      if(voicing.length > 2){
        if(Math.abs(button.index - voicing[j].index) > 4){
          return true
        }
      }
      if(Math.abs(button.index - voicing[j].index) > 8){
        return true
      }

    }
  }
  return false
}

function doesBassVoiceUseRootColumn(voicing){
  for( let i=0; i < voicing.length; i++ ){
    if (voicing[i]["label"] === "root"){
      return -1
    } else if(voicing[i]["label"] === "third") {
      return 1
    }
  }
  return 0
}

function getBassVoiceFingeringStretch(voicing){
  let stretch = voicing.length
  let inx = voicing[0]['index']

  for( let i=1; i < voicing.length; i++ ){
    stretch += Math.abs(inx - voicing[i]['index'])
    inx = voicing[i]['index']
  }

  return stretch
}
export {
  isPhysicallyImpossibleFingering,
  doesBassVoiceUseRootColumn,
  getBassVoiceFingeringStretch,
}
