const types = {
  UPDATE_SCREEN_SIZE: 'UPDATE_SCREEN_SIZE',
  //layout options
  TOGGLE_SHOW_OPTION_SCREEN: 'TOGGLE_SHOW_OPTION_SCREEN',
  TOGGLE_SHOW_CHORD_GENERATOR: 'TOGGLE_SHOW_CHORD_GENERATOR',
  TOGGLE_PERSECTIVE: 'TOGGLE_PERSECTIVE',
  TOGGLE_NOTES_ON_BUTTONS: 'TOGGLE_NOTES_ON_BUTTONS',
  TOGGLE_SMALL_KEYBOARD_OPTION: 'TOGGLE_SMALL_KEYBOARD_OPTION',

  TOGGLE_HELP_SCREEN: 'TOGGLE_HELP_SCREEN',
  TOGGLE_ABOUT_SCREEN: 'TOGGLE_ABOUT_SCREEN',

  KEYBOARD_LAYOUT_UPDATE: 'KEYBOARD_LAYOUT_SIZE_UPDATE',
  BASS_LAYOUT_UPDATE: 'BASS_LAYOUT_UPDATE',

  //UI music buttons/keys
  KEYBOARD_NOTE_SELECT: 'KEYBOARD_NOTE_SELECT',
  BASSBOARD_NOTE_SELECT: 'BASSBOARD_NOTE_SELECT',

  GENERATOR_CHORD_SELECT:'GENERATOR_CHORD_SELECT',
  KEYBOARD_UI_TOGGLE: 'KEYBOARD_UI_TOGGLE',

  CLEAR_KEYBOARD_BUTTONS: 'CLEAR_KEYBOARD_BUTTONS',
  CLEAR_BASS_BUTTONS: 'CLEAR_BASS_BUTTONS',
}

function updateScreenSize({value}){
  return {
    type: types.UPDATE_SCREEN_SIZE,
    value
  }
}

//options
function toggleShowChordGenerator({value}){
  return{
    type: types.TOGGLE_SHOW_CHORD_GENERATOR,
    value
  }
}
function toggleShowOptionScreen({value}){
  return{
    type: types.TOGGLE_SHOW_OPTION_SCREEN,
    value
  }
}
function togglePerspective({value}){
  return{
    type: types.TOGGLE_PERSECTIVE,
    value
  }
}
function toggleNotesOnButtons({value}){
  return{
    type: types.TOGGLE_NOTES_ON_BUTTONS,
    value
  }
}
function toggleSmallKeyboardOption({value}){
  return{
    type: types.TOGGLE_SMALL_KEYBOARD_OPTION,
    value
  }
}

function toggleHelpScreen({value}){
  return{
    type: types.TOGGLE_HELP_SCREEN,
    value
  }
}
function toggleAboutScreen({value}){
  console.log('value', value);
  return{
    type: types.TOGGLE_ABOUT_SCREEN,
    value
  }
}

//accordion layout
function updateKeyboardLayout({value}){
  return{
    type: types.KEYBOARD_LAYOUT_UPDATE,
    value
  }
}
function updateBassboardLayout({value}){
  return{
    type: types.BASS_LAYOUT_UPDATE,
    value
  }
}

//UI button / chord highlighting
function userSelectKeyboard({note, remove}) {//{note,  }
  return {
    type: types.KEYBOARD_NOTE_SELECT,
    note,
    remove,
  }
}

function userSelectBassboard({note, remove}) {//{note,  }
  return {
    type: types.BASSBOARD_NOTE_SELECT,
    note,
    remove,
  }
}

function userSelectChordGenerator({chord}) {//{note,  }
  return {
    type: types.GENERATOR_CHORD_SELECT,
    chord,
  }
}

function keyboardUI_toggle({value}) {
  return {
    type: types.KEYBOARD_UI_TOGGLE,
    value,
  }
}

//CLEAR / RESET UI
function clearBassButtons() {
  return {
    type: types.CLEAR_BASS_BUTTONS,
    value: [],
  }
}
function clearKeyboardButtons() {
  return {
    type: types.CLEAR_KEYBOARD_BUTTONS,
    value: [],
  }
}




export {
   types,

   updateScreenSize,

   toggleShowChordGenerator,
   toggleShowOptionScreen,
   togglePerspective,
   toggleNotesOnButtons,
   toggleSmallKeyboardOption,

   toggleHelpScreen,
   toggleAboutScreen,

   updateKeyboardLayout,
   updateBassboardLayout,

   userSelectKeyboard,
   userSelectBassboard,
   userSelectChordGenerator,
   keyboardUI_toggle,
   clearBassButtons,
   clearKeyboardButtons,
 }
