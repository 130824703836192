import React from "react";

class LightboxOverlay extends React.Component {

  render(){
    return(
      <div className={"scrollContainer"}>
        <div className={"LightboxOverlay "}></div>

        <div className={"LightBoxHeader"}>
          {this.props.header}
          <div className="closeButton" onClick={(payload) => {
            this.props.onClose(payload)
          }}>
            X
          </div>
        </div>

        <div className={"scrollContent"}>

          {this.props.children}

        </div>

      </div>

    )
  }
}

export default LightboxOverlay
