import {createStore, applyMiddleware} from 'redux'
import mainReducer from './reducer'
import {googleAnalytics} from './reactGAMiddlewares'

const DEFAULT = {
  //layout
  screenSizeVer: 0,

  showOptionScreen: false,
  perspectiveOption: 'player',// 'player' or 'mirror'
  showChordGenerator: true,
  showNotesOnButtons: true,
  smallKeyboardOption: false,

  showHelpScreen: false,
  showAboutScreen: false,

  keyboardNotesArray:[{note:'f',octave: 2}],
  bassNoteMatrix: {
    'minor'   :  [['eb', 'gb', 'bb'], ['bb', 'f',  'db'], ['f', 'ab', 'c'], ['c', 'eb', 'g'], ['g', 'bb', 'd'], ['d', 'f', 'a'], ['a', 'c', 'e'], ['e', 'g', 'b']],
    'major'   :  [['eb', 'g', 'bb'], ['bb', 'f',  'd'], ['f', 'a', 'c'], ['c', 'e', 'g'], ['g', 'b', 'd'], ['d', 'f#', 'a'], ['a', 'c#', 'e'], ['e', 'g#', 'b']],
    'root':  [['eb'], ['bb'], ['f'], ['c'], ['g'], ['d'], ['a'], ['e'], ['b'], ['f#']],
    'third':  [['g'], ['d'], ['a'], ['e'], ['b'], ['f#'], ['c#'], ['g#'], ['d#'], ['a#']],
  },

  //selected musical buttons/keys
  highlightedKeyboardNotes:[],
  highlightedBassNotes:[],
  keyboardUI_active: false,
}



export const store = createStore(mainReducer, DEFAULT, applyMiddleware(googleAnalytics))
