import React from "react";
import {connect} from 'react-redux'
import {
  toggleShowOptionScreen, toggleShowChordGenerator, togglePerspective,
  toggleHelpScreen, toggleAboutScreen
} from '../../redux/actions'

import ToggleButton from './ToggleButton'


class OptionBar extends React.Component {
  render(){
    return (
      <div className="OptionBar">
        <ToggleButton
          className="toggleShowOptionButton"
          value={this.props.showOptionScreen}
          state1obj={{value: true, label:'Options'}}
          state2obj={{value: false, label:'Options'}}
          onPress={(value) => {
            this.props.toggleShowOptionScreen({value: value})
           }}
        />

        <ToggleButton
          className="toggleShowOptionButton"
          value={this.props.showHelpScreen}
          state1obj={{value: true, label:'Help'}}
          state2obj={{value: false, label:'Help'}}
          onPress={(value) => {
            this.props.toggleHelpScreen({value: value})
           }}
        />

        <ToggleButton
          className="toggleShowOptionButton"
          value={this.props.showAboutScreen}
          state1obj={{value: true, label:'About'}}
          state2obj={{value: false, label:'About'}}
          onPress={(value) => {
            this.props.toggleAboutScreen({value: value})
           }}
        />

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    showOptionScreen: state.showOptionScreen,
    showChordGenerator: state.showChordGenerator,
    perspectiveOption: state.perspectiveOption,
    showHelpScreen: state.showHelpScreen,
    showAboutScreen: state.showAboutScreen,
  }
}

const mapActionToProps = {
  toggleShowOptionScreen,
  toggleShowChordGenerator,
  togglePerspective,
  toggleHelpScreen,
  toggleAboutScreen,

}

export default connect(mapStateToProps, mapActionToProps)(OptionBar)
