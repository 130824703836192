import React from "react";


const BellowsSection = (props) =>{
  return(
    <div className="BellowsSection">
      <div className="A_Bellow"></div>
      <div className="A_Bellow"></div>
      <div className="A_Bellow"></div>
      <div className="A_Bellow"></div>
      <div className="A_Bellow"></div>
      <div className="A_Bellow"></div>
      <div className="A_Bellow"></div>
      <div className="A_Bellow"></div>
    </div>

  )
}

export default BellowsSection
