import React from "react";

const HighlightNoteJSONDisplay = (props) => {
  return <div>
    <div>
      {'B:' + JSON.stringify(props.highlightedBassNotes)}
    </div>
    <div>
      {'KB:' +JSON.stringify(props.highlightedKeyboardNotes)}
    </div>
  </div>
}

export default HighlightNoteJSONDisplay
