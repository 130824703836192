const allChromatic = [
  ['a', 'bb', 'b', 'c',   'db', 'd', 'eb', 'e',  'f','gb', 'g', 'ab'],
  ['bb', 'b', 'c', 'db',  'd', 'eb', 'e', 'f',   'gb', 'g', 'ab', 'a' ],
  [ 'b', 'c', 'db', 'd',  'eb', 'e', 'f', 'gb',  'g', 'ab','a', 'bb'],
  ['c',  'db', 'd', 'eb', 'e',   'f', 'gb', 'g', 'ab','a', 'bb', 'b',],
  ['db', 'd', 'eb', 'e',  'f', 'gb', 'g', 'ab',  'a', 'bb', 'b', 'c' ],
  [ 'd', 'eb', 'e', 'f',  'gb', 'g', 'ab','a',   'bb', 'b', 'c',   'db'],
  ['eb', 'e', 'f', 'gb',  'g', 'ab', 'a', 'bb',  'b', 'c',  'db', 'd' ],
  ['e',  'f', 'gb', 'g',  'ab','a', 'bb', 'b',   'c', 'db', 'd', 'eb' ],
  ['f', 'gb', 'g', 'ab',  'a', 'bb', 'b', 'c',   'db', 'd', 'eb', 'e'   ],
  ['gb', 'g', 'ab','a',   'bb', 'b', 'c', 'db',  'd', 'eb', 'e',  'f' ],
  ['g', 'ab','a', 'bb',   'b', 'c', 'db', 'd',   'eb', 'e', 'f', 'gb' ],
  ['ab','a', 'bb', 'b',   'c', 'db', 'd', 'eb',  'e', 'f','gb', 'g'  ],
]


/*
function createChromaticMatrix(input){
  const chromaticMatrix = arr.map(note =>{
    return buildChromaticBasedOnRoot(note)
  })
  return chromaticMatrix
}

function buildChromaticBasedOnRoot(root){
  let chromatic = ['a', 'bb', 'b', 'c',   'db', 'd', 'eb', 'e',   'f', 'gb', 'g', 'ab']
  for(let i = 0; chromatic.indexOf(root) > 0; i++){
    chromatic.push(chromatic.shift('root'))
  }
  return chromatic;
}
*/

module.exports = {
  allChromatic,
}
