
const  KEYBOARD_INDEX = 'keyboardInx'
const  BASSBOARD_INDEX = 'bassboardInx'
const  SHOW_CHORD_GENERATOR = 'showChordGenerator'
const  PERSPECTIVE = 'perspectiveOption'
const  SHOW_NOTES = 'showNotesOnButtons'
const  SMALL_KEYBOARD = 'smallKeyboardOption'

function checkForExistingOptionsCookie(){
  if( !document.cookie || !document.cookie.length ) return false
  if( document.cookie.match(/(keyboardInx=)|(perspectiveOption=)/g) ){
    return true
  }
  return false
}

function cookieSaver(props, state){
  let settings = [
    `${KEYBOARD_INDEX}=${state[KEYBOARD_INDEX]};`,
    `${BASSBOARD_INDEX}=${state[BASSBOARD_INDEX]};`,
    `${SHOW_CHORD_GENERATOR}=${props[SHOW_CHORD_GENERATOR]};`,
    `${PERSPECTIVE}=${props[PERSPECTIVE]};`,
    `${SHOW_NOTES}=${props[SHOW_NOTES]};`,
    `${SMALL_KEYBOARD}=${props[SMALL_KEYBOARD]};`,
  ]
  for(let i=0; i<settings.length; i++){
    document.cookie = settings[i]
  }
}

function cookieToJSON(cookie){
  let cookieArray = cookie.split("; ")
  let cookieJSON = {}
  for(let i=0; i < cookieArray.length; i++){
    const kvPair = cookieArray[i].split('=')
    cookieJSON[kvPair[0]] = kvPair[1]
  }
  return cookieJSON;
}

function stringToBool(str=''){
  return (str.toLowerCase() === 'true')? true : false
}

export {
  checkForExistingOptionsCookie,
  cookieSaver,
  cookieToJSON,
  stringToBool,
}
