import React from 'react';
import { connect } from 'react-redux'
import { userSelectKeyboard } from '../redux/actions'
import { getEnharmonic } from '../chordFinder/chordFinder'

import PianoKey from './PianoKey'


class PianoKeyboard extends React.Component {

  handleKeyboardNoteSelect = (note, selected) => {
    const remove = selected
    this.props.userSelectKeyboard({note: getEnharmonic(note), remove})

  }

  isKeySelected = (note) => {
    return this.props.highlightedKeyboardNotes.some( item => {
      if(item && item.note){
        return item.note === note.note && item.octave === note.octave ? true : false
      }
      return false
    })
  }

  render(){
    return(
      <div className='keyboardContainer'>
          {
            this.props.keyboardNotesArray.length > 0 &&
            this.props.keyboardNotesArray.map( (note, i) => {
              return <PianoKey
                        key={i}
                        note={note.note}
                        octave={note.octave}
                        selected={this.isKeySelected(note)}
                        handleKeyboardNoteSelect={(note, selected) => this.handleKeyboardNoteSelect(note, selected)}
                        displayNote={this.props.showNotesOnButtons}
                      />
            })
          }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  highlightedKeyboardNotes: state.highlightedKeyboardNotes,
  showNotesOnButtons: state.showNotesOnButtons,

})


const mapActionToProps = {
  userSelectKeyboard,
}

export default connect(mapStateToProps, mapActionToProps)(PianoKeyboard)
