import React from "react";

class ToggleButton extends React.Component {

  onPress = () => {
    const {value, state1obj, state2obj, onPress} = this.props;

    

    (state1obj.value === value) ?
    onPress(state2obj.value) :
    onPress(state1obj.value)
  }

  render () {
    const {value, state1obj, state2obj, className} = this.props

    return (

      <div className={"ToggleButton " + className} onClick={this.onPress}>
        {  (state1obj.value === value) ?
            state1obj.label
            :
            state2obj.label
        }
      </div>
    )
  }
}

export default ToggleButton
