import React from "react";
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {
  checkForExistingOptionsCookie,
  cookieSaver,
  cookieToJSON,
  stringToBool
} from '../../utils/optionsCookieHelper'

import {
  toggleShowOptionScreen,
  toggleShowChordGenerator,
  togglePerspective,
  toggleNotesOnButtons,
  toggleSmallKeyboardOption,
  updateBassboardLayout,
  updateKeyboardLayout,
  userSelectChordGenerator,
} from '../../redux/actions'

import {layoutBassboard} from '../../utils/bassboardLayout'
import {keyboardTypes, createKeyboardLayoutArray} from '../../utils/keyboardLayout'

import LayoutOptions from './LayoutOptions'
import LightboxOverlay from './LightboxOverlay'
import SwitchButtonArray from './SwitchButtonArray'


//advanced options:
// minor third bass
// add 5th to the dominant 7 chord button
class OptionMenu extends React.Component{
  state = {
    bassboardInx: 8,
    keyboardInx: 3,
    saveMessage: 'Save your options to your browser so you don\'t need to set them each reload.',
  }

  updateBassboardLayoutInx = (index) => {
    this.setState({bassboardInx: index})
  }

  updateKeyboardLayoutInx = (index) => {
    this.setState({keyboardInx: index})
  }

  updateBassboardLayout = () => {

    const matrix = layoutBassboard(this.state.bassboardInx, this.props.perspectiveOption)
    this.props.updateBassboardLayout({value: matrix})
  }

  updateKeyboardLayout = () => {
    const kbObj = keyboardTypes[this.state.keyboardInx]
    this.props.updateKeyboardLayout({
      value: createKeyboardLayoutArray(kbObj.startingPoint,  kbObj.keys,  this.props.perspectiveOption)
    })
  }

  saveSettings = () => {
    cookieSaver(this.props, this.state)
    if(document.cookie && document.cookie.length){
      this.setState({saveMessage:'...'})
      setTimeout(()=>{
        this.setState({saveMessage:'Your settings have been saved.'})
      },200)
    }
  }

  componentDidMount(){
    console.log('OptionMenu COMP MOUNT', checkForExistingOptionsCookie());

    if(checkForExistingOptionsCookie()){
      console.log('COOKIE', document.cookie);
      const cookieJSON = cookieToJSON(document.cookie)
      cookieJSON['showChordGenerator'] && this.props.toggleShowChordGenerator( {value: stringToBool(cookieJSON['showChordGenerator'])} )
      cookieJSON['perspectiveOption'] && this.props.togglePerspective( {value: cookieJSON['perspectiveOption']} )
      cookieJSON['showNotesOnButtons'] && this.props.toggleNotesOnButtons( {value: stringToBool(cookieJSON['showNotesOnButtons'])} )
      cookieJSON['smallKeyboardOption'] && this.props.toggleSmallKeyboardOption( {value: stringToBool(cookieJSON['smallKeyboardOption'])} )
      this.setState({
        bassboardInx: parseInt(cookieJSON['bassboardInx']),
        keyboardInx:  parseInt(cookieJSON['keyboardInx'])
      }, () => {
        this.setState({saveMessage:'Your settings have loaded from a previous session.'})

        this.updateBassboardLayout()
        this.updateKeyboardLayout()
      })
    }else{
      this.updateBassboardLayout()
      this.updateKeyboardLayout()
    }
  }

  componentDidUpdate(prevProps, prevState){
    const perspectiveUpdate = (prevProps.perspectiveOption !== this.props.perspectiveOption)
    const bassboardLayoutUpdate = this.state.bassboardInx && (prevState.bassboardInx !== this.state.bassboardInx)
    const keyboardLayoutUpdate = this.state.keyboardInx && (prevState.keyboardInx !== this.state.keyboardInx)

    if(  bassboardLayoutUpdate || perspectiveUpdate ){
      this.updateBassboardLayout()
      this.props.userSelectChordGenerator({chord:[]})
    }
    if( keyboardLayoutUpdate || perspectiveUpdate){
      this.updateKeyboardLayout()
      this.props.userSelectChordGenerator({chord:[]})

    }
  }

  render(){
    return(
      <React.Fragment>
       {this.props.showOptionScreen ?
      <LightboxOverlay
        onClose={() => this.props.toggleShowOptionScreen({value:false})}
        header={
          <>
            <div style={{paddingRight:"20px"}}>Options</div>
            <div className="saveButton small" onClick={this.saveSettings}>Save</div>
          </>}
      >

          <div className="OptionMenu">
            <div className={'optionSection'}>
              <div className={'optionRow'}>
                <div className={'optionRowLabel'}> Chord Generator: </div>
                <SwitchButtonArray
                  selected={this.props.showChordGenerator}
                  handleSelection={ (value) => { this.props.toggleShowChordGenerator({value: value})} }
                  choices={[
                    { label: 'Show', value: true, },
                    { label: 'Hide', value: false, },
                  ]}
                />
              </div>
              <div className={'optionRow'}>
              <div className={'optionRowLabel'}> Perspective: </div>

                <SwitchButtonArray
                  selected={this.props.perspectiveOption}
                  handleSelection={ (value) => { this.props.togglePerspective({value: value})} }
                  choices={[
                    { label: 'Player', value: 'player', },
                    { label: 'Mirror', value: 'mirror', },
                    // { label: 'Spectator', value: 'spectator',},
                  ]}
                />

              </div>
              <div className={'optionRow'}>
                <div className={'optionRowLabel'}>
                  Tone labels:
                </div>
                <SwitchButtonArray
                  selected={this.props.showNotesOnButtons}
                  handleSelection={ (value) => { this.props.toggleNotesOnButtons({value: value})} }
                  choices={[
                    { label: 'Show', value: true, },
                    { label: 'Hide', value: false, },
                  ]}
                />
              </div>

              <div className={'optionRow'}>
                <div className={'optionRowLabel'}>
                  Keyboard Size:
                </div>
                <SwitchButtonArray
                  selected={this.props.smallKeyboardOption}
                  handleSelection={ (value) => { this.props.toggleSmallKeyboardOption({value: value})} }
                  choices={[
                    { label: 'Small', value: true, },
                    { label: 'Large', value: false, },
                  ]}
                />
              </div>
            </div>
            <br/>
            <div className="optionSection">
              <LayoutOptions
                bassboardInx={this.state.bassboardInx}
                keyboardInx={this.state.keyboardInx}
                updateBassboardLayoutInx={(index) => this.updateBassboardLayoutInx(index)}
                updateKeyboardLayoutInx={(index) => this.updateKeyboardLayoutInx(index)}
              />

            </div>

          <div className="saveButton" onClick={this.saveSettings}>Save</div>
          <div className="saveButtonFinePrint">

            <p>
            <span style={{fontSize:'14px'}}>{this.state.saveMessage}</span><br/>
            Saving uses cookies.
            </p>
          </div>
        </div>

      </LightboxOverlay>
      :
      null
    }
    </React.Fragment>

    )
  }
}

OptionMenu.propTypes = {
  updateBassboardLayout: PropTypes.func,
  updateKeyboardLayout: PropTypes.func,
}


const mapStateToProps = (state) => {
  return {
    perspectiveOption: state.perspectiveOption,
    showOptionScreen: state.showOptionScreen,
    showChordGenerator: state.showChordGenerator,
    showNotesOnButtons: state.showNotesOnButtons,
    smallKeyboardOption: state.smallKeyboardOption,
  }
}

const mapActionToProps = {

  toggleShowOptionScreen, toggleShowChordGenerator, togglePerspective, toggleNotesOnButtons, toggleSmallKeyboardOption,
  updateBassboardLayout, updateKeyboardLayout,
  userSelectChordGenerator,

}
export default connect(mapStateToProps, mapActionToProps)(OptionMenu)
