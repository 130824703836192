import teoria from 'teoria'

const stradellaTypes = [
  {
    bass: 12,
    version: '12-bass (2x6)',
    startingPoint: 'a',
    columns: ['root', 'major'],
    description:'Six rows: two columns: bass and major chord',
  },
  {
    bass: 24,
    version: '24 bass (3x12)',
    startingPoint: 'e',
    columns: ['root', 'major', 'minor'],
    description: '',
  },
  {
    bass: 32,
    version: '32 bass (4x8)',
    startingPoint: 'e',
    columns: ['root', 'major', 'minor', '7'],
    description: '',
  },
  {
    bass: 40,
    version: '40 bass (5x8)',
    startingPoint: 'e',
    columns: ['third', 'root', 'major', 'minor', '7'],
    description: '',
  },
  {
    bass: 48,
    version: '48 bass (4x12)',
    startingPoint: 'f#',
    columns: ['third', 'root', 'major', 'minor'],
    description:'12 rows, in 4 columns',
  },
  {
    bass: 48,
    version: '48 bass (6x8)',
    startingPoint: 'e',
    columns: ['third', 'root', 'major', 'minor', '7', 'dim'],
    description:'8 rows, in 6 columns',
  },
  {
    bass: 60,
    version: '60 bass (5x12)',
    startingPoint: 'f#',
    columns: ['third', 'root', 'major', 'minor', '7'],
    description:'12 rows, in 5 columns: 2 bass, major chord, minor chord, and 7th chord',
  },
  {
    bass: 72,
    version: '72 bass (6x12)',
    startingPoint: 'f#',
    columns: ['third', 'root', 'major', 'minor', '7', 'dim'],
    description:'12 rows, in 6 columns: 2 bass, major chord, minor chord, 7th chord, and diminished chord',
  },
  {
    bass: 80,
    version: '80 bass (5x16)',
    startingPoint: 'ab',
    columns: ['third', 'root', 'major', 'minor', '7'],
    description:'16 rows, in 5 columns: 2 bass, major chord, minor chord, 7th chord',
  },
  {
    bass: 96,
    version: '96 bass (6x16)',
    startingPoint: 'ab',
    columns: ['third', 'root', 'major', 'minor', '7', 'dim'],
    description:'16 rows, in 5 columns: 2 bass, major chord, minor chord, 7th chord, and diminished chord',
  },
  {
    bass: 120,
    version: '120 bass (6x20)',
    startingPoint: 'bb',
    columns: ['third', 'root', 'major', 'minor', '7', 'dim'],
    description:'20 rows, in 5 columns: 2 bass, major chord, minor chord, 7th chord, and diminished chord',
  },
]

function layoutBassboard(index=0, perspective){
  const stradellaObject = stradellaTypes[parseInt(index)]
  const {bass, startingPoint, columns} = stradellaObject
  let matrix = {}

  for( let i =0; i < columns.length; i++){
    matrix[columns[i]] = makeBassColumn(startingPoint, columns[i], (bass/columns.length), perspective)
  }
  return matrix
}

function makeBassColumn(startingPoint, chordType, colLength, perspective){
  let column = []

  const addToArr = perspective === 'mirror' ? 'push' : 'unshift'
  let note = teoria.note(startingPoint)
  for( let i =0; i < colLength; i++){
    if(chordType === 'root' || chordType === 'third'){
      column[addToArr](getOneNote(note, chordType))
    }else{
      chordType = chordType === 'dim'? 'dim7' : chordType
      column[addToArr](getTriad(note, chordType))
    }

    note = note.interval('P4')
    note = normalizeAccidentals( note.name() + note.accidental() )
  }
  return column
}

function normalizeAccidentals(noteStr){
  if(noteStr.toLowerCase() === 'cb'){
    return teoria.note('b')
  }
  else if(noteStr.toLowerCase() === 'db'){
    return  teoria.note('c#')

  }
  else{
    return teoria.note(noteStr)
  }
}

function getOneNote(note, chordType){
  if(chordType === 'third'){
     note = note.interval('M3')
  }
  return [note.name() + note.accidental()]
}

function getTriad(note, chordType){
  const chordObj = note.chord(chordType)
  if(chordType === '7'){
    chordObj.voicing(['P1', 'M3', 'm7'])
  }
  else if(chordType === 'dim7'){
    chordObj.voicing(['P1', 'm3', 'M6'])
  }

  return chordObj.simple()
}



export {
  stradellaTypes,
  layoutBassboard,
}
