import {allChromatic} from './scaleDatabase'

const chordData = require('./chordData.json');

/*
Database of chords based on root intervals
categorized in the length (2, 3, 4, or 5 note chords)

cycle through 12 notes, testing each as a root,
subtracting the difference of each to create an array of intervals,
then compare the intervals against the database's
array of inputs, testing each as a root
*/

// const testArr = ['c', 'eb', 'gb', ]
// chordFinder(testArr)


function chordFinder(input){
  const notes = sanitizeInput(input)

  const chordMatrix = allChromatic.map(chromaArr => {
    const compared = compareIntervals(notes, chromaArr).sort((a, b) =>  a - b )
    return [ chromaArr[0], ...compared]
  })

  if(notes.length >= 2 && notes.length <= 7 ){
    const data = chordData[notes.length - 2]
    return checkForChords(chordMatrix, data)
  }
}

function sanitizeInput(input){

  return input.map(note => getEnharmonic(note).toLowerCase() )
}

function checkForChords(chordMatrix, data){
  let answers = []

  for(let possible in data){
    for(let chord of chordMatrix){
      // console.log(chord);
      if(checkIfChordsAreEqual(data[possible], chord) ){
        answers.push(formatChord(chord[0], possible))
      }
    }
  }

  return answers
}

function getEnharmonic(note){
  const enharmonic = {
  	'c#':'db',
  	'd#':'eb',
  	'f#':'gb',
  	'g#':'ab',
  	'a#':'bb',
    'abb':'g',
    'bbb':'a',
    'cbb':'bb',
    'dbb':'c',
    'ebb':'d',
    'fbb':'eb',
    'gbb':'f',
    'fb': 'e',
    'e#': 'f',
    'cb': 'b',
    'b#': 'c',
  }
  return enharmonic[note] || note
}


function compareIntervals(input, chromaArr){
  return input.map( function(note){
    return chromaArr.indexOf(note)
  })
}

function checkIfChordsAreEqual(chord1, chord2){
  for(let i=0; i < chord1.length; i++){
    if(chord1[i] !== chord2[i + 1]){ //offset since chord2 contains the root note at the first index
      return false
    }
  }
  return true
}

function formatChord(note, chord){
  const noteFinal = note.slice(0,1).toUpperCase() + note.slice(1).toLowerCase()
  //add some display options
  return noteFinal + chord
}



//module.exports = chordFinder
export {chordFinder, getEnharmonic}
